import React from 'react';

import dribbleLogo from '../../assets/icons/dribbble.png';
import linkedin from '../../assets/icons/linkedin.png';
import twitter from '../../assets/icons/twitter.png';

const dribbles = [
    'https://cdn.dribbble.com/userupload/5564373/file/original-a7bc009ca1598b8c1ce89349908d348f.jpg?resize=1024x768',
    'https://cdn.dribbble.com/userupload/12298977/file/original-ffd1bba22052ac9e6a902be2dd201775.jpg?resize=1024x1024',
    'https://cdn.dribbble.com/users/8194039/screenshots/19222170/media/2aa9cc983127b0305faa27a7b8ff5cfa.jpg?resize=768x576&vertical=center'
]

function Socials() {

    const Dribble = () => {
        return(
            <a className='col-span-2 md:col-span-1' href='https://dribbble.com/fvturedesigns' target='_blank' rel="noreferrer">
                <div className='w-full h-full flex flex-col bg-[#fff] rounded-[15px] border border-[#eee] cursor-pointer hover:bg-[#f5f5f5]'>
                    <div className='p-5 pb-3'>
                        <div className='flex items-center gap-1'>
                            <img src={dribbleLogo} alt='' className='h-[40px] w-[40px] rounded-lg object-cover'/>
                            <div className='flex-1 flex justify-end'><button className='bg-[#EA4C88] rounded-full text-white px-4 py-2 font-medium text-sm'>Follow</button> </div>
                        </div>
                        <p className='mt-2 text-sm'>Fvture Dribbble. Nothing too serious, just a couple light headed designs</p>
                    </div>
                    <div className='flex-1 pb-3 grid grid-cols-[100px_1fr_100px] gap-2 items-center'>
                        <img src={dribbles[1]} alt='' className='rounde h-full w-full object-cover' />
                        <img src={dribbles[0]} alt='' className='roundd h-full w-full object-cover' />
                        <img src={dribbles[2]} alt='' className='rouned h-full w-full object-cover' />
                    </div>
                </div>
            </a>
        )
    }

    return (
        <div className='w-full'>
            <p className='font-medium text-lg pl-5'>Fvture on Social Media</p>
            <div className='mt-5 grid grid-cols-2 gap-5'>
                <Dribble />
                <div className='h-full w-full grid gap-5 grid-cols-2 md:grid-cols-1 col-span-2 md:col-span-1'>
                    <a href='https://www.linkedin.com/in/ahumareze/' target='_blank' rel="noreferrer">
                        <div className='w-full bg-[#fff] rounded-[15px] border border-[#eee] cursor-pointer hover:bg-[#f5f5f5]'>
                            <div className='p-5 pb-3'>
                                <div className='flex items-center gap-1'>
                                    <img src={linkedin} alt='' className='h-[40px] w-[40px] rounded-lg object-cover'/>
                                    {/* <div className='flex-1 flex justify-end'> <a href='https://dribbble.com/fvturedesigns' target='_blank'><button className='bg-[#EA4C88] rounded-full text-white px-4 py-2 font-medium text-sm'>Follow</button></a> </div> */}
                                </div>
                                <p className='mt-2 text-xs md:text-sm line-clamp-2'>Ifeanyi Ahumareze. Occasionally repost contents I find helpful on LinkedIn </p>
                            </div>
                        </div>
                    </a>
                    <a href='https://twitter.com/ifeanyicodes' target='_blank' rel="noreferrer">
                        <div className='w-full bg-[#fff] rounded-[15px] border border-[#eee] cursor-pointer hover:bg-[#f5f5f5]'>
                            <div className='p-5 pb-3'>
                                <div className='flex items-center gap-1'>
                                    <img src={twitter} alt='' className='h-[40px] w-[40px] rounded-lg object-cover'/>
                                    <div className='flex-1 hidden md:flex justify-end'><button className='bg-black rounded-full text-white px-4 py-2 font-medium text-sm'>Follow</button></div>
                                </div>
                                <p className='mt-2 text-xs md:text-sm line-clamp-2'>Visit my twitter where I post my takes on the olympics and music related content</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Socials