import React from 'react'
import Projects from '../../components/projects/Projects'
import Socials from '../../components/socials/Socials'
import Photos from '../../components/photos/Photos';
import coffee from '../../assets/icons/coffee.png';
import profile from '../../assets/profile_pic.jpeg';

function Home() {
  return (
    <div>
      <div className='w-full bg-white font-poppins flex'>
        <div className='max-w-[1200px] mx-auto px-[20px] flex flex-col md:flex-row gap-10'>
          <div className='md:h-screen md:w-[350px] bg-[#fff] md:sticky top-0 px-[20px] md:px-0 pt-[50px]'>
            <img className='h-[120px] w-[120px] md:h-[180px] md:w-[180px] rounded-full object-cover' src={profile} alt='ifeanyi ahumareze' />
            <h1 className='text-3xl font-semibold mt-3'>Ifeanyi 👽 Fvture</h1>
            <p className='text-lg mt-1 opacity-80'>I build cool stuffs for the inter-web. Currently listening to Brymo's Yellow </p>
            <a href="mailto:ahumarezeifeanyi001@gmail.com.com" target='_blank' rel="noreferrer"><button className='w-full h-[55px] bg-black text-white rounded-[15px] text-lg mt-7'>Let's Work</button></a>
            <a href="https://www.buymeacoffee.com/fvture" target='_blank' rel="noreferrer"><button className='w-full h-[55px] bg-[#eee] border border-[#ddd] flex items-center justify-center gap-2 text-black rounded-[15px] text-lg mt-3 font-medium'> <img src={coffee} alt='coffee' className='h-[25px]' /> Buy me coffee</button></a>
          </div>
          <div className='flex-1 flex flex-col gap-10 py-[20px] md:py-[50px]'>
            <Socials />
            <Projects />
            <Photos />
          </div>
        </div>
      </div>
      <div className='font-poppins bg-[#eee] dark:bg-dark-bg text-black py-2 font-medium text-center'>Built with ❤️ + ☕ by <a href='https://twitter.com/ifeanyicodes' target='_blank' rel="noreferrer" className='underline'>Fvture</a></div>
    </div>
  )
}

export default Home