import React from 'react'
import { PROJECTS } from '../../constants/projects'

function Projects() {

    const ProjectItem = ({img, details, link, displayLink}) => {
        return(
            <a href={link} target='_blank' rel="noreferrer">
                <div className='w-full h-fit rounded-[20px] bg-[#fff] hover:bg-[#f5f5f5] cursor-pointer border border-[#eee] p-5'>
                    <img className='h-[40px] w-[40px] rounded-lg border border-[#eee]' src={`https://icon.horse/icon/${displayLink}`} alt='' />
                    <p className='text-sm mt-3 line-clamp-2'>{details}</p>
                    <p className='opacity-80 text-xs mt-1'>{displayLink}</p>
                    <img className='w-full h-[170px] rounded-lg object-cover mt-5' src={img} alt='ifeanyi project' />
                </div>
            </a>
        )
    }

    return (
        <div className='w-full'>
            <p className='font-medium text-lg pl-5'>Recent Projects</p>
            <div className='mt-5 grid grid-cols-1 md:grid-cols-2 gap-5'>
                {PROJECTS.map((i, idx) => (
                    <ProjectItem 
                        img={i.img}
                        details={i.details[0]}
                        link={i.link}
                        displayLink={i.displayLink}
                        key={idx}
                    />
                ))}
            </div>
        </div>
    )
}

export default Projects