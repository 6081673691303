import React, { useEffect } from 'react'

function ProfileAnimations() {

    useEffect(() => {
        window.location.href = 'https://magnificent-sunburst-fc7be1.netlify.app/';
      }, []);

    return (
        <div className='w-full h-screen flex items-center justify-center'>
            <h3 className='text-2xl font-medium'>Redirecting ...</h3>
        </div>
    )
}

export default ProfileAnimations