import React from 'react';

import cafe_one_hangout from '../../assets/photos/cafe_one_hangout.jpeg';
import cats from '../../assets/photos/cats.jpeg';
import laptop from '../../assets/photos/laptop.jpeg';
import kanye from '../../assets/photos/kanye.jpeg';

function Photos() {
    return (
        <div className='w-full'>
            <p className='font-medium text-lg pl-5'>Photos I took 📸</p>
            <div className='mt-5 flex flex-col md:grid grid-cols-10 grid-rows-[100px_100px_100px_100px_100px_100px_100px] gap-5'>
                <div className='col-start-1 col-end-6 row-span-2 overflow-hidden bg-[#f5f5f5]'>
                    <img className='w-full h-full  rounded-[15px] object-cover' src={cats} alt='' />
                </div>
                <div className='col-start-6 col-end-11 row-span-4 overflow-hidden bg-[#f5f5f5]'>
                    <img className='w-full h-full  rounded-[15px] object-cover' src={kanye} alt='' />
                </div>
                <div className='col-start-1 col-end-6 row-span-2 overflow-hidden bg-[#f5f5f5]'>
                    <img className='w-full h-full rounded-[15px] object-cover' src={laptop} alt='' />
                </div>
                <div className='col-span-10 row-span-3 overflow-hidden bg-[#f5f5f5]'>
                    <img className='w-full h-full rounded-[15px] object-cover' src={cafe_one_hangout} alt='' />
                </div>
            </div>
        </div>
    )
}

export default Photos