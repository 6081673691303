import React, { useEffect } from 'react'

function AshleyWebsiteRedirect() {

    useEffect(() => {
        window.location.href = 'https://aesthetic-cuchufli-716961.netlify.app/';
      }, []);

    return (
        <div className='w-full h-screen flex items-center justify-center'>
            <h3 className='text-2xl font-medium'>Redirecting ...</h3>
        </div>
    )
}

export default AshleyWebsiteRedirect