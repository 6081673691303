import { createBrowserRouter, redirect } from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home';
import AshleyWebsiteRedirect from './pages/redirectPages/AshleyWebsiteRedirect';
import RedirectHome from './pages/redirectPages/RedirectHome';
import ProfileAnimations from './pages/redirectPages/ProfileAnimations';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '*',
    element: <RedirectHome />
  },
  {
    path: '/playgrounds/ashley-osimehen',
    element: <AshleyWebsiteRedirect />
  },
  {
    path: '/playgrounds/nft-avatars',
    element: <ProfileAnimations />
  }
])
