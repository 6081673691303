import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function RedirectHome() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/', {replace: true})
    }, [])

    return (
        <div>RedirectHome</div>
    )
}

export default RedirectHome